import * as React from "react";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import Box from "common/components/Box";
import Container from "@mui/material/Container";
import { useQueryParams, StringParam } from 'use-query-params';

const ThankYouPage = ({ location }) => {

  const [query, setQuery] = useQueryParams({
    name: StringParam,
    source: StringParam
  })
  return (
    <Layout showContactMap={query.source === 'website-get-started' ? false : true} 
      showFreeConsult={query.source === 'website-get-started' ? false : true} 
      location={location.pathname}>
          <Seo title="Thank you" />
          <Container minWidth="400px" sx={{
            marginTop: "100px",
            padding: "40px",
            border: "1px solid #efefef",
            borderRadius: "10px",
            marginBottom: "20px",
            minHeight: "600px"
          }}>
            {query.source !== 'website-get-started' ? <Box>
              <h3>
                Awesome! You have taken the first step in a 5 step process to get your MAXIMUM Employee Retention Credit
              </h3>
              <h5>
                Here are the next steps in the process:
              </h5>
              <ol>
                <li>
                  You will receive a copy of our engagement letter at the email you specified. The
                  document is provided as an electronic agreement that you can sign on your mobile phone or compute. Please
                  review it carefully. If you agree with the terms please sign it electronically.<br />
                  <p>
                    <b>There is no upfront payment required.</b>
                  </p>
                </li>
                <li>
                  <p>
                    Once the agreement is signed we will contact you to determine how you would like us to access your
                    payroll system. We recommend creating a login/password for our team that can be used to run reports.
                    <p><b>Our team is fully versed with most payroll systems. We can guide you through the process.</b></p>
                  </p>
                  <p>
                    As an alternative you can also provide us with your credentials. We will treat your credentials with
                    care and confidentiality. They will only be used to run the necessary reports required to compute
                    your ERTC.
                  </p>
                </li>
                <li>
                  <p>
                    We will inform you when we have all the data and work on your package has begun. We will also provide
                    you with routine updates until the package is delivered to you.
                  </p>
                </li>
                <li>
                  <p>Once we have computed your credit and produced an <b>audit ready package</b>, we will provide
                    this package to you electronically along with filing instructions.</p>
                </li>
                <li>
                  <p><b>Payment is due upon receipt of the final package with filing instructions.</b></p>
                </li>
              </ol>
              <h4>
                Thank you for giving us the opportunity to be of service to you. <br /><br />2020 and 2021 have been difficult years especially
                for small businesses. We hope this service will make things a little easier.
              </h4>

            </Box>
              :
              <Box>
                <h3>
                  Awesome! You have taken the first step in a 5 step process to get your
                  MAXIMUM Employee Retention Credit
                </h3>
                <p>
                  The next step in the process is a quick 10 minute phone call where we give you an overview of the
                  credit. We will also answer any questions you have and finally give you a rough estimate of
                  the credit you should expect to receive.
                </p>
                <h4>
                  Thank you for giving us the opportunity to be of service to you. <br /><br />2020 and 2021 have been difficult years especially
                  for small businesses. We hope this service will make things a little easier.
                </h4>
              </Box>}

          </Container>
    </Layout>
  );
};

export default ThankYouPage;
